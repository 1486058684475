body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



header.masthead {
  padding-top: 10rem;
  padding-bottom: 6rem;
  text-align: center;
  /* color: $white; */
  /* background-image: url(); */
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;

  .masthead-subheading {
      font-size: 1.5rem;
      font-style: italic;
      line-height: 1.5rem;
      margin-bottom: 25px;
      /* commenting out for now to remove compiler warning */
      /* font-family: $subheadings-font-family; */
  }

  .masthead-heading {
      font-size: 3.25rem;
      font-weight: 700;
      line-height: 3.25rem;
      margin-bottom: 2rem;
      /* commenting out for now to remove compiler warning */
      /* font-family: $headings-font-family; */
  }
}

@include media-breakpoint-up(md) {
  header.masthead {
      padding-top: 12.5rem + 4.5rem;
      padding-bottom: 12.5rem;

      .masthead-subheading {
          font-size: 2.25rem;
          font-style: italic;
          line-height: 2.25rem;
          margin-bottom: 2rem;
      }

      .masthead-heading {
          font-size: 4.5rem;
          font-weight: 700;
          line-height: 4.5rem;
          margin-bottom: 4rem;
      }
  }
}